import React from 'react';
import {Grid} from "@material-ui/core";


export default function Customer(props) {

    return(
        <Grid>
            <h1>Please contact your real estate agent for a preview link!</h1>
        </Grid>
    );
}

