import React from 'react';
import Adjustments from "./Adjustments";



export default function Options(props) {

    return (
        <form className='adjustment-form'>
            {props.value === 'homes' ?
                <>
                    {props.adjustments['homes'].map((a, index) => {
                            return (
                                <Adjustments {...a} key={index} />
                            )
                    })}
                </>

                :
                <></>
            }
            {props.value === 'condos' ?
                <>
                    {props.adjustments['condos'].map((a, index) => {
                        return (
                            <Adjustments {...a} key={index} />
                        )
                    })}
                </>

                :
                <></>
            }
        </form>
    );
}
