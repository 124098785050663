import React from 'react';
import Box from '@material-ui/core/Box';
import {Container} from "@material-ui/core";

export default function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Container maxWidth="xl">
                    <Box>{children}</Box>
                </Container>
            )}
        </div>
    );
}
