import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './Pages/Home';
import Preview from './Pages/Preview';
import Create from './Pages/Create';
import Customer from './Pages/Customer';
import Header from "./components/Header";
import {Container, Grid} from "@material-ui/core";

import React from "react";


function App() {

    return (
        <>
            <Header />
            <Container maxWidth="xl" className="real-estate-calc__wrapper">
                <Grid>
                    <Router>
                        <Switch>
                            <Route path="/create">
                                <Create/>
                            </Route>
                            <Route path="/customer">
                                <Customer/>
                            </Route>
                            <Route path="/preview">
                                <Preview/>
                            </Route>
                            <Route path="/">
                                <Home/>
                            </Route>
                        </Switch>
                    </Router>
                </Grid>
            </Container>
        </>
    );
}

export default App;
