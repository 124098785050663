import React from 'react';
import MaterialTable from 'material-table';

Date.prototype.ddmmyyyy = function() {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        (mm>9 ? '' : '0') + mm,
        (dd>9 ? '' : '0') + dd,

        this.getFullYear()
    ].join('/');
};

export default function EditableTable() {
    const {useState} = React;

    const [letter, setLetter] = useState(67);
    var date = new Date();


    const [columns, setColumns] = useState([
        {title: '', field: 'name', editable: 'never', initialEditValue: `Comp ${String.fromCharCode(letter)}`},
        {title: 'Address', field: 'address', initialEditValue: '123 street'},
        {title: 'Bedroom', field: 'br', type: 'numeric', initialEditValue: 1},
        {title: 'Bathroom', field: 'bathr', type: 'numeric', initialEditValue: 1},
        {title: 'Lot Size', field: 'ls', type: 'numeric', initialEditValue: 1},
        {title: 'Square Footage', field: 'sqft', type: 'numeric', initialEditValue: 1},
        {title: 'Parking', field: 'parking', type: 'numeric', initialEditValue: 0},
        {title: 'Storage', field: 'storage', type: 'numeric', initialEditValue: 0},
        {title: 'Renovations', field: 'reno', type: 'numeric', initialEditValue: 0},
        {title: 'View', field: 'view', type: 'numeric', initialEditValue: 0},
        {title: 'Days on Market', field: 'DoM', type: 'numeric', initialEditValue: 0},
        {title: 'Date Sold', field: 'sold', type: 'date', initialEditValue: date.ddmmyyyy()},
        {title: 'Sold Price', field: 'soldprice', type: 'numeric', initialEditValue: 0},
    ]);

    const [data, setData] = useState([
        {
            id: 1,
            name: 'Subject Property',
            address: '123 street',
            br: 3,
            bathr: 2,
            ls: 3000,
            sqft: 1800,
            parking: 2,
            storage: 1,
            reno: 5,
            view: 0,
            DoM: 0,
            sold: null,
            soldprice: 0
        },
        {
            id: 3,
            name: 'Adjustments',
            br: 1,
            bathr: 2,
            ls: 3000,
            sqft: 1800,
            parking: 2,
            storage: 1,
            reno: 5,
            view: 0,
            parentId: 2
        },

        {
            id: 2,
            name: 'Comp A',
            address: '123 street',
            br: 5,
            bathr: 4,
            ls: 3400,
            sqft: 2300,
            parking: 2,
            storage: 0,
            reno: 0,
            view: 0,
            DoM: 23,
            sold: date.ddmmyyyy(),
            soldprice: 100000
        },
        {
            id: 4,
            name: 'Comp B',
            address: '123 street',
            br: 3,
            bathr: 4,
            ls: 3020,
            sqft: 1600,
            parking: 1,
            storage: 3,
            reno: 2,
            view: 0,
            DoM: 0,
            sold:  date.ddmmyyyy(),
            soldprice: 150000
        },
        {
            id: 5,
            name: 'Adjustments',
            br: 1,
            bathr: 2,
            ls: 3000,
            sqft: 1800,
            parking: 2,
            storage: 1,
            reno: 5,
            view: 0,
            parentId: 4
        },

    ]);

    return (
        <MaterialTable
            style={{width: '100%'}}
            title="Cell Editable Preview"
            columns={columns}
            data={data}
            options={{
                search: false
            }}
            parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
            cellEditable={{
                onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                    return new Promise((resolve, reject) => {
                        const index = data.findIndex(s => s.name === rowData.name);
                        if (index > -1) {
                            data[index][columnDef.field] = newValue;
                        }
                        setTimeout(resolve, 1000);
                    });
                }
            }}

            editable={{
                onRowAdd: newData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            newData.id = data.length + 1;
                            const adjustments = {
                                id: newData.id + 1,
                                name: 'Adjustments',
                                br: 1,
                                bathr: 2,
                                ls: 3000,
                                sqft: 1800,
                                parking: 2,
                                storage: 1,
                                reno: 5,
                                view: 0,
                                parentId: newData.id
                            };

                            console.log([...data, newData, adjustments]);
                            setData([...data, newData, adjustments]);
                            const updateLetter = letter + 1;
                            columns[0].initialEditValue = `Comp ${String.fromCharCode(updateLetter)}`;
                            setColumns(columns);
                            setLetter(updateLetter);
                            resolve();
                        }, 1000)
                    })
            }}
        />
    )
}
