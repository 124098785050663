import React from 'react';
import Options from "./Options";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {Grid} from "@material-ui/core";
import EditableTable from "./EditableTable";


export default function HomeTab(props) {

    const adjustments = {
        homes: [
            {
                id: 'adjustment-bedrooms',
                label: 'Bedrooms',
                adornmentValue: ['$', '/br'],
                adornmentPosition: ['start', 'end'],
                labelWidth: 85
            },
            {
                id: 'adjustment-bathrooms',
                label: 'Bathrooms',
                adornmentValue: ['$', '/bath'],
                adornmentPosition: ['start', 'end'],
                labelWidth: 90
            },
            {
                id: 'adjustment-lot-size',
                label: 'Lot Size',
                adornmentValue: ['$', 'sq/ft'],
                adornmentPosition: ['start', 'end'],
                labelWidth: 70
            },
            {
                id: 'adjustment-sqft',
                label: 'Square Footage',
                adornmentValue: ['$', 'per sq/ft'],
                adornmentPosition: ['start', 'end'],
                labelWidth: 135
            },
            {
                id: 'adjustment-parking',
                label: 'Parking',
                adornmentValue: ['$', '/stall'],
                adornmentPosition: ['start', 'end'],
                labelWidth: 65
            },
            {
                id: 'adjustment-renovation',
                label: 'Renovation',
                adornmentValue: ['$'],
                adornmentPosition: ['start'],
                labelWidth: 95
            },
            {
                id: 'adjustment-storage',
                label: 'Storage',
                adornmentValue: ['$'],
                adornmentPosition: ['start'],
                labelWidth: 65
            },
            {
                id: 'adjustment-age',
                label: 'Age',
                adornmentValue: ['$', '/year'],
                adornmentPosition: ['start', 'end'],
                labelWidth: 35
            },
            {
                id: 'adjustment-view',
                label: 'View',
                adornmentValue: ['$'],
                adornmentPosition: ['start'],
                labelWidth: 40
            },
            {
                id: 'adjustment-other',
                label: 'Other',
                adornmentValue: ['$'],
                adornmentPosition: ['start'],
                labelWidth: 50
            }
        ],
        condos: [
            {
                id: 'adjustment-bedrooms',
                label: 'Bedrooms',
                adornmentValue: ['$', '/br'],
                adornmentPosition: ['start', 'end'],
                labelWidth: 85
            },
            {
                id: 'adjustment-bathrooms',
                label: 'Bathrooms',
                adornmentValue: ['$', '/bath'],
                adornmentPosition: ['start', 'end'],
                labelWidth: 90
            },
            {
                id: 'adjustment-sqft',
                label: 'Square Footage',
                adornmentValue: ['$', 'per sq/ft'],
                adornmentPosition: ['start', 'end'],
                labelWidth: 135
            },
            {
                id: 'adjustment-parking',
                label: 'Parking',
                adornmentValue: ['$', '/stall'],
                adornmentPosition: ['start', 'end'],
                labelWidth: 65
            },
            {
                id: 'adjustment-renovation',
                label: 'Renovation',
                adornmentValue: ['$'],
                adornmentPosition: ['start'],
                labelWidth: 95
            },
            {
                id: 'adjustment-storage',
                label: 'Storage',
                adornmentValue: ['$'],
                adornmentPosition: ['start'],
                labelWidth: 65
            },
            {
                id: 'adjustment-age',
                label: 'Age',
                adornmentValue: ['$', '/year'],
                adornmentPosition: ['start', 'end'],
                labelWidth: 35
            },
            {
                id: 'adjustment-view',
                label: 'View',
                adornmentValue: ['$'],
                adornmentPosition: ['start'],
                labelWidth: 40
            },
            {
                id: 'adjustment-other',
                label: 'Other',
                adornmentValue: ['$'],
                adornmentPosition: ['start'],
                labelWidth: 50
            },
        ]
    }

    return (
        <Grid>
            <h1>{props.title}</h1>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="adjustments"
                    id="accordion-adjustment__title"
                >
                    <Typography component={'div'}>Adjustments</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Options value={props.value} adjustments={adjustments}/>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="comparison properties"
                    id="accordion-comparison_properties__title"
                >
                    <Typography component={'div'}>Comparable Properties</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <EditableTable />
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}
