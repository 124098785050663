import React from 'react';
import {FormControl, InputAdornment, InputLabel, OutlinedInput} from "@material-ui/core";


export default function Adjustments(props) {

    const {id, label, labelWidth, adornmentValue, adornmentPosition} = props;
    const startIndex = adornmentPosition.indexOf('start');
    const endIndex = adornmentPosition.indexOf('end');
    return (
        <FormControl variant="outlined">
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <OutlinedInput
                id={id}
                startAdornment={startIndex > -1 ? <InputAdornment position="start">{adornmentValue[startIndex]}</InputAdornment>: <></>}
                endAdornment={endIndex > -1 ? <InputAdornment position="end">{adornmentValue[endIndex]}</InputAdornment>: <></>}
                labelWidth={labelWidth}
                size={'small'}
            />
        </FormControl>
    );
}
