import React from 'react';
import {Grid, Button, ButtonGroup} from "@material-ui/core";
import {useHistory} from 'react-router-dom';

export default function Home(props) {
    const history = useHistory();
    console.log(history);
    return(
        <Grid>
            <h1>Welcome to the Real Estate Estimate Calculator.</h1>
            <h2>Please Select Your User Type</h2>
            <ButtonGroup size="large" color="primary" aria-label="real estate agent or client">
                <Button onClick={() => {history.push('/create')}}>Real Estate Agent</Button>
                <Button color="secondary" onClick={() => {history.push('/customer')}}>Customer</Button>
            </ButtonGroup>
        </Grid>
    );
}
