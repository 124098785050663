import React, {useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

export default function Header(props) {

    const [currentPath, setCurrentPath] = useState(window.location.pathname);
    const [title, setTitle] = useState('Real Estate Estimate Calc');

    useEffect(() => {

        const titles = {
            '/create': 'Create Your Estimate'
        }
        const {pathname} = window.location;
        let title = titles[pathname] ? titles[pathname]: 'Real Estate Estimate Calc';
        setTitle(title);
        setCurrentPath(pathname);
    }, [currentPath, setTitle, setCurrentPath]);


    return (
        <>
            <AppBar position="static">
                <Toolbar variant="dense">
                    <Typography variant="h6" color="inherit">
                        {title}
                    </Typography>
                </Toolbar>
            </AppBar>
        </>
    );
}

