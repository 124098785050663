import React from 'react';
import {Grid} from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from "../components/TabPanel";
import HomeTab from "../components/HomeTab";

export default function Create(props) {

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return(
        <Grid>
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Condo/Townhouses" />
                <Tab label="Duplex/Detached Homes" />
            </Tabs>
            <TabPanel value={value} index={0}>
                <HomeTab title='Condo/Townhouses' value='condos' />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <HomeTab title='Duplex/Detached Homes' value='homes' />
            </TabPanel>
        </Grid>
    );
}

